import React from 'react'

import { Alert } from 'reactstrap'

// GraphQL
import { graphql, withApollo } from '@apollo/client/react/hoc'

import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getLicenseOwner, getLicenseOwners, updateLicenseOwner } from './license-owners.graphql'

import Loading from '../../../helper/loading/loading.jsx'
// additional components
import FormGroups from './form'

const LicenseOwnerUpdate = ({ locale, history, client, toggleModal, data: { loading, error, license_owner } }) => {
  if (loading) return <Loading />
  if (error) return <p>Error</p>
  return (
    <LicenseeForm
      locale={locale}
      license_owner={license_owner}
      client={client}
      id={license_owner.id}
      history={history}
      toggleModal={toggleModal}
    />
  )
}

class LicenseeForm extends React.Component {
  constructor(props) {
    super(props)

    const { license_owner, id } = this.props

    this.state = {
      license_owner,
      variables: {
        id,
        license_owner: {
          signature_text: license_owner?.signature?.text || '',
          allowsAutomatedTenderCreation: license_owner?.allowsAutomatedTenderCreation,
          priceInfoText: license_owner?.priceInfoText || '',
          default_user_id: license_owner?.default_user?.id || '',
          default_dsft_user_id: license_owner?.default_dsft_user?.id || '',
          stateOfOperation: license_owner?.stateOfOperation || '',
          organization_ids: license_owner?.organizations?.map(({ id }) => id) || [],
          name: license_owner?.name || '',
          address: {
            street: license_owner?.address?.street || '',
            street_addition: license_owner?.address?.street_addition || '',
            zipcode: license_owner?.address?.zipcode || '',
            city: license_owner?.address?.city || '',
            country_code: license_owner?.address?.country?.key || 'DE',
            state: license_owner?.address?.state?.key || ''
          },
          contact_person: {
            salutation: license_owner?.contact_person?.salutation?.key || '',
            title: license_owner?.contact_person?.title || '',
            first_name: license_owner?.contact_person?.first_name || '',
            last_name: license_owner?.contact_person?.last_name || '',
            function: license_owner?.contact_person?.function || '',
            phone: license_owner?.contact_person?.phone || '',
            email: license_owner?.contact_person?.email || '',
            address: {
              street: license_owner?.contact_person?.address?.street || '',
              street_addition: license_owner?.contact_person?.address?.street_addition || '',
              zipcode: license_owner?.contact_person?.address?.zipcode || '',
              city: license_owner?.contact_person?.address?.city || '',
              country_code: license_owner?.contact_person?.address?.country?.key || 'DE',
              state: license_owner?.contact_person?.address?.state?.key || ''
            }
          },
          region_ids: license_owner?.regions?.map(region => region.id) || [],
          parent_id: license_owner?.master?.id || ''
        }
      },
      error: false,
      error_msg: ''
    }
  }

  change = event =>
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            [event.target.id]: {
              $set: event.target.value
            }
          }
        }
      })
    )

  changeContactSalutation = salutation => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              salutation: {
                $set: salutation.value
              }
            }
          }
        }
      })
    )
  }

  changeFederalState = state => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            stateOfOperation: {
              $set: state
            }
          }
        }
      })
    )
  }

  changeContactPerson = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }

  changeContactPersonAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              address: {
                [event.target.id]: {
                  $set: event.target.value
                }
              }
            }
          }
        }
      })
    )
  }

  changeLicenseOwner = license_owner => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            parent_id: {
              $set: license_owner.value
            }
          }
        }
      })
    )
  }

  changeRegions = selectedOption => {
    const regionId = selectedOption.value

    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            region_ids: {
              $set: regionId ? [regionId] : []
            }
          }
        }
      })
    )
  }

  changeLicenseOwnerAddress = event => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              [event.target.id]: {
                $set: event.target.value
              }
            }
          }
        }
      })
    )
  }

  changeLicenseOwnerAddressState = state => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              state: {
                $set: state.value
              }
            }
          }
        }
      })
    )
  }
  changeLicenseOwnerAddressCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            address: {
              country_code: {
                $set: country.value
              }
            }
          }
        }
      })
    )
  }

  changeContactAddressState = state => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              address: {
                state: {
                  $set: state.value
                }
              }
            }
          }
        }
      })
    )
  }

  changeContactAddressCountry = country => {
    this.setState(
      update(this.state, {
        variables: {
          license_owner: {
            contact_person: {
              address: {
                country_code: {
                  $set: country.value
                }
              }
            }
          }
        }
      })
    )
  }

  addSignature = e =>
    this.setState(update(this.state, { variables: { license_owner: { signature_file: { $set: e.target.files[0] } } } }))

  addLogo = e =>
    this.setState(update(this.state, { variables: { license_owner: { logo: { $set: e.target.files[0] } } } }))

  onChange = event => {
    const fragments = event.target.id.split('.')

    var value = event.target.value
    const variables = deepNestInObjectsWithKeys(value, ...fragments, '$set')

    this.setState(update(this.state, { variables }))
  }

  onError = ({ graphQLErrors }) =>
    this.setState({
      processing: false,
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    this.setState({ processing: true })

    const { variables } = this.state
    this.props.client
      .mutate({
        mutation: updateLicenseOwner,
        variables,
        refetchQueries: [
          {
            query: getLicenseOwners
          }
        ],
        awaitRefetchQueries: true
      })
      .then(() => this.setState({ processing: false }, this.props.toggleModal()))
      .catch(error => this.onError(error))
  }

  render = () => (
    <React.Fragment>
      {this.state.errors && <Alert color="danger">{this.state.error_msg}</Alert>}
      <FormGroups
        changeContactAddressCountry={this.changeContactAddressCountry}
        changeContactAddressState={this.changeContactAddressState}
        changeContactSalutation={this.changeContactSalutation}
        changeFederalState={this.changeFederalState}
        changeLicenseOwner={this.changeLicenseOwner}
        changeLicenseOwnerAddressCountry={this.changeLicenseOwnerAddressCountry}
        changeLicenseOwnerAddressState={this.changeLicenseOwnerAddressState}
        changeRegions={this.changeRegions}
        variables={this.state.variables}
        client={this.props.client}
        response={this.state.license_owner}
        license_owner={this.state.variables.license_owner}
        locale={this.props.locale}
        submit={this.submit}
        onChange={this.onChange}
        toggleModal={this.props.toggleModal}
        errors={this.state.errors}
        id={this.props.id}
        change={this.change}
        addLogo={this.addLogo}
        addSignature={this.addSignature}
        processing={this.state.processing}
      />
    </React.Fragment>
  )
}

export default withApollo(
  graphql(getLicenseOwner, {
    options: ({ id }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        id
      }
    })
  })(LicenseOwnerUpdate)
)
